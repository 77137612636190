<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_de_la_structure__dashboard"
      />
    </v-row>

    <v-row v-if="mounted" no-gutters class="informationsdemonclub__layout">
      <v-col cols="12" color="blue" sm="4" lg="3">
        <v-row class="informationsdemonclub__sidebar block" no-gutters>
          <v-col cols="12" class="informationsdemonclub__sidebar-avatar py-4">
            <Avatar
              :context="{ ID_Structure: currentStructureId }"
              size="102"
            />
          </v-col>

          <v-col cols="12" class="informationsdemonclub__sidebar-navigation">
            <Navigation :key="NavigationKey"></Navigation>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="8" lg="9" class="informationsdemonclub__content">
        <v-row
          class="informationsdemonclub__content-content-view test__dematerialise h-100"
          no-gutters
        >
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Avatar from "@/components/Common/Common__Avatar.vue";
import SubBar from "@/components/Common/Common__SubBar.vue";
import Navigation from "@/components/GestionDeLaStructure/Affiliations/Affiliations__Navigation.vue";
import { bus } from "@/main";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    subBarTitle: "Gestion de l'affiliation",
    mounted: false,
    isActive: false,
    NavigationKey: 0,
  }),

  components: { Avatar, SubBar, Navigation },

  async created() {
    const selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
    
    if (!selectedSeason || selectedSeason.ID_EtapeSaison === 5) {
      this.$router.push({ name: "gestion_de_la_structure__dashboard" });
    }

    const affiliation = await this.getStructureAffiliation({
      ID_Structure: this.currentStructureId,
      ID_Saison: selectedSeason.id,
    });

    if (selectedSeason.ID_EtapeSaison < 3 || affiliation.ID_EtapeAffiliation === 3) {
      this.$router.push({ name: "gestion_de_la_structure__dashboard" });
    }

    this.mounted = true;
    bus.$on("onRefreshNavigation", () => {
      console.log('onRefreshNavigation');
      this.refreshNavigation();
      return true;
    });
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("saisons", ["saisons"]),
  },

  methods: {
    ...mapActions("structure", ["getStructureAffiliation"]),

    refreshNavigation() {
      this.NavigationKey++;
    },
  },
};
</script>

<style lang="scss">
.test__dematerialise {
  padding: 0px !important;
}
</style>
