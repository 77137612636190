<template>
  <ul class="d-flex flex-column justify-space-between pl-0">
    <liNav
    title="Etape 1 - Saisie des informations générales"
    module_name="affiliations__informations_generales"
    auth
    />
    <span v-if="!step1IsValid">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <liNav
              title="Etape 2 - Saisie des activités à joindre"
              module_name="affiliations__activites_du_club"
              auth
              :disabled="!step1IsValid"
            />
          </span>
        </template>
        <span>Valider l'étape précédente pour pouvoir accéder à cette étape</span>
      </v-tooltip>
    </span>
    <liNav
      v-else
      title="Etape 2 - Saisie des activités à joindre"
      module_name="affiliations__activites_du_club"
      auth
    />
    <span v-if="!step1IsValid || !step2IsValid">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <liNav
              title="Etape 3 - Saisie des documents"
              module_name="affiliations__documents_a_joindre"
              auth
              :disabled="!step1IsValid || !step2IsValid"
            />
          </span>
        </template>
        <span>Valider l'étape précédente pour pouvoir accéder à cette étape</span>
      </v-tooltip>
    </span>
    <liNav
      v-else
      title="Etape 3 - Saisie des documents"
      module_name="affiliations__documents_a_joindre"
      auth
    />
    <span v-if="!step1IsValid || !step2IsValid || !step3IsValid">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <liNav
              title="Etape 4 - Saisie des salariés"
              module_name="affiliations__gestion_des_salaries"
              auth
              :disabled="!step1IsValid || !step2IsValid || !step3IsValid"
            />
          </span>
        </template>
        <span>Valider l'étape précédente pour pouvoir accéder à cette étape</span>
      </v-tooltip>
    </span>
    <liNav
      v-else
      title="Etape 4 - Saisie des salariés"
      module_name="affiliations__gestion_des_salaries"
      auth
    />
    <span v-if="!step1IsValid || !step2IsValid || !step3IsValid || !step4IsValid">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <liNav
              title="Etape 5 - Synthèse et validation"
              module_name="affiliations__tableau_de_synthese"
              auth
              :disabled="!step1IsValid || !step2IsValid || !step3IsValid || !step4IsValid"
            />
          </span>
        </template>
        <span>Valider l'étape précédente pour pouvoir accéder à cette étape</span>
      </v-tooltip>
    </span>
    <liNav
      v-else
      title="Etape 5 - Synthèse et validation"
      module_name="affiliations__tableau_de_synthese"
      auth
    />
  </ul>
</template>

<script>
import liNav from "@/components/Common/Navigation__item";
import { mapActions, mapGetters } from "vuex";
import StructureType from "@/constants/structureType";
import { getStructureDocumentsByStructureId } from "../../../plugins/structureService";
import { GENERAL_MEETING_REPORT, INSURANCE_CERTIFICATE, PROFESSIONAL_CARD, RESULT_REPORT, SUPERVISOR_DIPLOMA } from "../../../constants/structureDocumentType";

export default {
  components: { liNav },

  data: () => ({
    selectedSeason: null,
    step1IsValid: false,
    step2IsValid: false,
    step3IsValid: false,
    step4IsValid: false,
    structureDisabilities: [],
    affiliations: null,
    structureDocuments: [],
  }),

  async created() {
    this.selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
    this.affiliations = await this.getStructureAffiliation({
      ID_Structure: this.currentStructureId,
      ID_Saison: parseInt(this.selectedSeason.id, 10),
    });

    this.structureDisabilities = await this.getStructureDisabilities(this.currentStructureId);
    this.structureDisabilities = this.structureDisabilities.map(
      (disability) => disability.id_disability
    );

    const structureDocuments = await getStructureDocumentsByStructureId(this.currentStructureId);
    this.structureDocuments = structureDocuments;

    this.validateStep1();
    this.validateStep2();
    this.validateStep3();
    this.validateStep4();
  },

  computed: {
    ...mapGetters("structure", [
      "getStructure",
      "currentStructureId",
      "currentStructure"
    ]),
    ...mapGetters("saisons", ["saisons"]),
    isStructureEA() {
      return this.currentStructure.STR_Structure.ID_TypeStructure === StructureType.ClubEtablissementAffilie;
    },

    DOC_CompteRenduAg() {
      const strDocGeneralMeeting = this.structureDocuments.find(doc => doc.type === GENERAL_MEETING_REPORT);
      const compteRenduAg = this.affiliations.DOC_CompteRenduAg;

      if (strDocGeneralMeeting && compteRenduAg) {
          return new Date(strDocGeneralMeeting.createdAt) > new Date(compteRenduAg.Z_DateCreation) 
              ? { data : strDocGeneralMeeting, isNewDoc: true }  
              : { data: compteRenduAg, isNewDoc: false };
      } else if (strDocGeneralMeeting) {
          return { data : strDocGeneralMeeting, isNewDoc: true };
      } else if (compteRenduAg) {
          return { data: compteRenduAg, isNewDoc: false };
      }

      return this.getDefaultDoc("compte_rendu_ag");
    },

    DOC_Status() {
      const strDocStatus = this.structureDocuments.find(doc => doc.type === RESULT_REPORT);
      const status = this.affiliations.DOC_Status;

      if (strDocStatus && status) {
          return new Date(strDocStatus.createdAt) > new Date(status.Z_DateCreation) 
              ? { data : strDocStatus, isNewDoc: true }  
              : { data: status, isNewDoc: false };
      } else if (strDocStatus) {
          return { data : strDocStatus, isNewDoc: true };
      } else if (status) {
          return { data: status, isNewDoc: false };
      }

      return this.getDefaultDoc("status");
    },

    DOC_AttestationAssurance() {
      const strDocInsuranceCertificate = this.structureDocuments.find(doc => doc.type === INSURANCE_CERTIFICATE);
      const attestationAssurance = this.affiliations.DOC_AttestationAssurance;

      if (strDocInsuranceCertificate && attestationAssurance) {
          return new Date(strDocInsuranceCertificate.createdAt) > new Date(attestationAssurance.Z_DateCreation) 
              ? { data : strDocInsuranceCertificate, isNewDoc: true }  
              : { data: attestationAssurance, isNewDoc: false };
      } else if (strDocInsuranceCertificate) {
          return { data : strDocInsuranceCertificate, isNewDoc: true };
      } else if (attestationAssurance) {
          return { data: attestationAssurance, isNewDoc: false };
      }

      return this.getDefaultDoc("attestation_assurance");
    },

    DOC_CarteProfessionnelle() {
      const strDocProfessionalCard = this.structureDocuments.find(doc => doc.type === PROFESSIONAL_CARD);
      const carteProfessionnelle = this.affiliations.DOC_CarteProfessionnelle;

      if (strDocProfessionalCard && carteProfessionnelle) {
          return new Date(strDocProfessionalCard.createdAt) > new Date(carteProfessionnelle.Z_DateCreation) 
              ? { data : strDocProfessionalCard, isNewDoc: true }  
              : { data: carteProfessionnelle, isNewDoc: false };
      } else if (strDocProfessionalCard) {
          return { data : strDocProfessionalCard, isNewDoc: true };
      } else if (carteProfessionnelle) {
          return { data: carteProfessionnelle, isNewDoc: false };
      }

      return this.getDefaultDoc("copie_cartes_professionnelles");
    },

    DOC_DiplomeEncadrant() {
      const strDocSupervisorDiploma = this.structureDocuments.find(doc => doc.type === SUPERVISOR_DIPLOMA);
      const diplomeEncadrant = this.affiliations.DOC_DiplomeEncadrant;

      if (strDocSupervisorDiploma && diplomeEncadrant) {
          return new Date(strDocSupervisorDiploma.createdAt) > new Date(diplomeEncadrant.Z_DateCreation) 
              ? { data : strDocSupervisorDiploma, isNewDoc: true }  
              : { data: diplomeEncadrant, isNewDoc: false };
      } else if (strDocSupervisorDiploma) {
          return { data : strDocSupervisorDiploma, isNewDoc: true };
      } else if (diplomeEncadrant) {
          return { data: diplomeEncadrant, isNewDoc: false };
      }

      return this.getDefaultDoc("diplome_encadrant");
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListTypeFonctions",
    ]),
    ...mapActions("utilisateurs", [
      "getListDirigeants",
    ]),
    ...mapActions("structure", [
      "getStructureAffiliation",
      "getStructureActivite",
      "getStructureType",
      "getStructureDisabilities",
    ]),

    getDefaultDoc(slugType) {
      let doc = {
        CheminDocument: "",
        DateFinValiditeDocument: "",
        EST_Actif: true,
        EST_DocumentValide: false,
        ID_Structure: this.context.ID_Structure,
        ID_Type_Document: "",
        NomDocument: "",
      };
      let type = this.allDocumentType.find(
        (doc) => doc.SlugTypeDocument == slugType
      );
      doc.ID_Type_Document = type?.id;

      return { data: doc, isNewDoc: false };
    },

    async validateStep1() {
      let fonctiontList = await this.getListTypeFonctions();
      let structure = await this.getStructure(this.currentStructureId);
      let payload = {
        where: {
          EST_Actif: {
            _eq: true
          },
          ID_Structure: {
            _eq: this.currentStructureId
          }
        }
      };
      let utilisateurs = await this.getListDirigeants(payload);

      let correspondant = utilisateurs.find((user) =>
          user.ID_Utilisateur ==
          structure.STR_Structure.ID_NomCorrespondant
      );

      let presidentType = fonctiontList.find(
        (fct) => fct.SlugFonction == "president"
      );

      if (
        correspondant &&
        structure.STR_Structure.ID_AdresseCorrespondance &&
        (utilisateurs.some((user) => user.ID_Fonction == presidentType.id) || this.isStructureEA)
      ) {
        this.step1IsValid = true;
        return true;
      }

      this.step1IsValid = false;
      return false;
    },

    async validateStep2() {
      let activities = await this.getStructureActivite(this.currentStructureId);

      const isThereMainActivity = activities.some((activity) => activity.EST_ActivitePrincipale === true);
      if (activities.length === 0 || !isThereMainActivity) {
        this.step2IsValid = false;

        return false;
      }

      if (
        this.affiliations.CLUB_EST_AcceuillantHandicape !== null &&
        ((this.affiliations.CLUB_EST_AcceuillantHandicape === true && this.structureDisabilities.length > 0) || this.affiliations.CLUB_EST_AcceuillantHandicape === false) &&
        this.affiliations.CLUB_EST_AcceuillantMineur !== null &&
        this.affiliations.CLUB_AcceuillantMineurAge !== null &&
        this.affiliations.CLUB_EST_AcceptePassSportCulture !== null &&
        this.affiliations.CLUB_EST_PretMateriel !== null &&
        this.affiliations.CLUB_EST_ConventionAllocationFamilliale !== null &&
        this.affiliations.CLUB_EST_AccepteChequesVacances !== null &&
        this.affiliations.CLUB_EST_AccepteCouponSport !== null &&
        this.affiliations.CLUB_EST_PreparePasseport !== null
      ) {
        this.step2IsValid = true;

        return true;
      }

      this.step2IsValid = false;

      return false;
    },

    async validateStep3() {
      const isGeneralMeetingDocValid = (this.DOC_CompteRenduAg.isNewDoc && this.DOC_CompteRenduAg.data.file.id) || (!this.DOC_CompteRenduAg.isNewDoc && this.DOC_CompteRenduAg.data.CheminDocument);

      let structureType = await this.getStructureType({
        ID_Structure: this.currentStructureId,
      });
      structureType = structureType.LIST_StructureType;

      if (structureType.EST_Club) {
        if (
          structureType.SlugTypeStructure == "club_association"
          || structureType.SlugTypeStructure == "club_section_association"
        ) {
          if (isGeneralMeetingDocValid) {
            this.step3IsValid = true;

            return true;
          }
        }

        if (structureType.SlugTypeStructure === 'club_etablissement_affilie') {
          const isSupervisorDiplomaDocValid = (this.DOC_DiplomeEncadrant.isNewDoc && this.DOC_DiplomeEncadrant.data.file.id) || (!this.DOC_DiplomeEncadrant.isNewDoc && this.DOC_DiplomeEncadrant.data.CheminDocument);
          const isProfessionalCardDocValid = (this.DOC_CarteProfessionnelle.isNewDoc && this.DOC_CarteProfessionnelle.data.file.id) || (!this.DOC_CarteProfessionnelle.isNewDoc && this.DOC_CarteProfessionnelle.data.CheminDocument);
          const isInsuranceCertificateDocValid = (this.DOC_AttestationAssurance.isNewDoc && this.DOC_AttestationAssurance.data.file.id) || (!this.DOC_AttestationAssurance.isNewDoc && this.DOC_AttestationAssurance.data.CheminDocument);

          if (
            isSupervisorDiplomaDocValid
            && isProfessionalCardDocValid
            && isInsuranceCertificateDocValid
          ) {
            this.step3IsValid = true;
            return true;
          }
        }
      }

      const isStatusDocValid = (this.DOC_Status.isNewDoc && this.DOC_Status.data.file.id) || (!this.DOC_Status.isNewDoc && this.DOC_Status.data.CheminDocument);

      if (
        isGeneralMeetingDocValid
        && isStatusDocValid
      ) {
        this.step3IsValid = true;
        return true;
      }

      this.step3IsValid = false;
      return false;
    },

    async validateStep4() {
      let affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSeason.id,
      });

      // nb hours sal need to be setted.
      if (affiliation.SAL_EquivalentTempsPlein == null) {
        this.step4IsValid = false;
        return false;
      }

      // if nb hours ===0 then collecteur formation pro isnt needed
      if (+affiliation.SAL_EquivalentTempsPlein == 0) {
        this.step4IsValid = true;
        return true;
      } else {
        if (affiliation.ID_CollecteurFormationProfessionnelle) {
          this.step4IsValid = true;
          return true;
        }
      }

      this.step4IsValid = false;
      return false;
    },
  },
};
</script>
