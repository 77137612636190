<template>
  <div>
    <ModalFiles
      :key="modalKey"
      :isOpen="modalIsOpen"
      @onClose="closeModal"
      @onFileSelected="fileSelected"
      withValidationDate
      @onFileUploaded="fileUploaded"
      directUpload
    ></ModalFiles>

    <v-row>
      <v-col cols="12">
        <div class="font-weight-bold"><slot name="title"></slot></div>
        <div class="font-weight-thin"><slot name="sub_title"></slot></div>
        <div class="mt-2">
          <v-chip @click.prevent="modalIsOpen = true" class="ma-2" small>{{
            currentFileName
          }}</v-chip>
          <v-chip v-if="currentFileDate" class="ma-2" small
            ><span class="font-weight-bold mr-2">Date de signature:</span
            >{{ currentFileDate }}</v-chip
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ModalFiles from "@/components/utils/modals/fileUpload/fileUpload.vue";
import { mapGetters, mapActions } from "vuex";
import { error_notification } from "@/plugins/notifications";
export default {
  components: { ModalFiles },

  data: () => ({
    selectedFile: null,
    modalIsOpen: false,
    modalKey: 0,
  }),
  created() {},

  computed: {
    ...mapGetters("basics", ["getDocumentType"]),

    currentFileName() {
      if (this.selectedFile) return this.selectedFile.filename;
      if (this.document) return this.document.NomDocument;
      return "Veuillez choisir un fichier";
    },

    currentFileDate() {
      if (this.selectedFile)
        return this.$moment(this.selectedFile.validationDate).format(
          "DD/MM/YYYY"
        );
      if (this.document)
        return this.$moment(this.document.DateValidation).format("DD/MM/YYYY");
      return "";
    },
  },

  methods: {
    ...mapActions("basics", ["insertDocument"]),

    closeModal() {
      this.modalIsOpen = false;
      this.modalKey += 1;
    },
    fileSelected(file) {
      this.selectedFile = file;
    },

    async fileUploaded(file) {
      if (!file) return;
      let payload = {
        CheminDocument: file.url,
        NomDocument: file.filename,
        DocumentType: this.documentType,
        ID_Structure: this.ID_Structure,
        DateValidation: this.$moment(file.date),
      };
      if (this.document && this.document.id) {
        payload.ID_Document = this.document.id;
      }
      try {
        let document = await this.insertDocument(payload);
        this.$emit("onDocumentSaved", {
          documentType: this.documentType,
          document: document,
          DocUploadComponent: this,
        });
      } catch (e) {
        error_notification(
          "Oups, une erreur est survenue lors du téléchargement du document..."
        );
      }

      //
    },
  },

  props: {
    documentType: {
      type: Object,
    },
    ID_Structure: {
      type: Number,
    },
    document: {
      type: Object,
      default: null,
    },
  },
};
</script>
