<template>
  <div>
    <v-row no-gutters>
      <SubBar
        title="INFORMATIONS DE LA STRUCTURE"
        route_name="gestion_de_la_structure__dashboard"
      />
    </v-row>

    <v-row v-if="mounted" no-gutters class="informationsdemonclub__layout">
      <v-col
        cols="12"
        color="blue"
        sm="4"
        lg="3"
        class="scrollable"
        style="background: #e7effd"
      >
        <v-row
          class="information-club-navigation"
          no-gutters
          style="height: 100%"
        >
          <v-col cols="12" class="py-4">
            <Avatar
              :context="{ ID_Structure: currentStructureId }"
              size="102"
              :upload="canChangeAvatar"
              textEditLink="Changer le logo"
              :noNotice="false"
              labelButton="Changer le logo"
              :isLogo="true"
            />
            <div class="d-flex flex-column align-center text-center mt-3">
              <span
                class="structure-name"
                v-text="
                  currentStructure.STR_Structure &&
                  currentStructure.STR_Structure.NomStructure
                "
              />
              <!-- <span
                class="description-structure mt-3"
                v-text="descriptionStructure"
              /> -->
            </div>
          </v-col>

          <v-col cols="12">
            <Navigation></Navigation>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="8" lg="9" class="informationsdemonclub__content">
        <v-row
          class="informationsdemonclub__content-content-view h-100"
          no-gutters
        >
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Avatar from "@/components/Common/Common__Avatar.vue";
import SubBar from "@/components/Common/Common__SubBar.vue";
import Navigation from "@/components/GestionDeLaStructure/InformationStructure/Navigation.vue";

export default {
  data: () => ({
    mounted: false,
  }),

  components: { Avatar, SubBar, Navigation },

  created() {
    this.mounted = true;
    console.log("this.currentStructure :>> ", this.currentStructure);
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "currentStructureId"]),

    canChangeAvatar() {
      return this.currentStructure.STR_Structure.LIST_StructureType.EST_Club;
    },
    descriptionStructure() {
      if (this.currentStructure.STR_Structure.LIST_StructureType?.Description) {
        return this.currentStructure.STR_Structure.LIST_StructureType
          ?.Description;
      }
      return this.currentStructure.STR_Structure.INFO_Presentation;
    },
  },
};
</script>

<style lang="scss">
.structure-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 48px;
  letter-spacing: -1px;
  color: #000000;
  width: 350px;
}
.description-structure {
  width: 250px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 150;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}
</style>
