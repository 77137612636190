var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.admin && _vm.auth && _vm.canAccess)?_c('router-link',{class:{
    link: true,
    'link--disabled': _vm.disabled,
  },attrs:{"to":{ name: _vm.module_name },"disabled":_vm.disabled,"event":!_vm.disabled ? 'click' : ''}},[_c('span',{staticClass:"nav-text",domProps:{"textContent":_vm._s(_vm.title)}})]):(_vm.auth)?_c('router-link',{class:{
    link: true,
    'link--disabled': _vm.disabled,
  },attrs:{"to":{ name: _vm.module_name },"disabled":_vm.disabled,"event":!_vm.disabled ? 'click' : ''}},[_c('span',{staticClass:"nav-text",domProps:{"textContent":_vm._s(_vm.title)}})]):(_vm.admin && _vm.canAccess)?_c('router-link',{class:{
    link: true,
    'link--disabled': _vm.disabled,
  },attrs:{"to":{ name: _vm.module_name },"disabled":_vm.disabled,"event":!_vm.disabled ? 'click' : ''}},[_c('span',{staticClass:"nav-text",domProps:{"textContent":_vm._s(_vm.title)}})]):_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({staticClass:"nolink"},on),[_vm._v(_vm._s(_vm.title))])]}}])},[_c('span',[_vm._v("Vous n'avez pas les droits nécessaire pour accéder à ce module. Merci de contacter un administrateur.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }