<template>
  <div class="table__structure_list">
    <v-data-table
      :class="{ 'filters-opened': areFiltersOpened }"
      :headers="headers"
      :fixed-header="true"
      :loading="loading"
      :items="items"
      item-key="id"
      class="elevation-1 list__table"
    >
      <template v-slot:item.factures="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="light-blue"
              outlined
              @click.prevent="
                downloadFacture(
                  item,
                  `facture-${item.UTI_Utilisateur.CT_Prenom}-${item.UTI_Utilisateur.CT_Nom}-saison${item.ID_Saison}`,
                  item.UTI_Utilisateur
                )
              "
              key="edit"
            >
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
          </template>
          <span>Télécharger la facture de paiement</span>
        </v-tooltip>
      </template>
      <template v-slot:item.attestations="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="light-blue"
              outlined
              @click.prevent="
                downloadAttestation(
                  item,
                  `attestation-${item.UTI_Utilisateur.CT_Prenom}-${item.UTI_Utilisateur.CT_Nom}-saison${item.ID_Saison}`,
                  item.UTI_Utilisateur
                )
              "
              key="edit"
            >
              <v-icon>mdi-file-cloud</v-icon>
            </v-btn>
          </template>
          <span>Télécharger l'attestation</span>
        </v-tooltip>
      </template>
      <template v-slot:item.periode="{ item }">
        <span class="periode__class"> {{ item.depart }} </span>
        <br />
        <span class="periode__class"> {{ item.retour }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="green"
              outlined
              @click.prevent="visualizeVoyage(item)"
              key="edit"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Visualiser la déclaration de voyage</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="error"
              outlined
              @click.prevent="declineVoyage(item)"
              key="edit"
            >
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span>Déclinez la demande de voyage à l'étranger</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <ConfirmationRefus
      :openDialog="openDeclineDialog"
      @answerNo="() => (openDeclineDialog = false)"
      @answerYes="declineVoyageUser"
    />
    <DetailVoyage
      :openModal="openDetailDialog"
      @on-close="() => (openDetailDialog = false)"
      :context="details"
      @onPrintAttestation="downloadAttestation"
      @onPrintFacture="downloadFacture"
      :key="details.id"
    />

    <PrintAttestation ref="attestation" :print="print"></PrintAttestation>
    <PrintFacture ref="facture" :print="print"></PrintFacture>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ConfirmationRefus from "@/components/utils/modals/Licence/ConfirmationRefus";
import DetailVoyage from "@/components/utils/modals/Voyage/DetailsVoyage";
import PrintAttestation from "../../Assurances/PrintAttestation.vue";
import PrintFacture from "../../Assurances/PrintFacture.vue";

export default {
  components: {
    ConfirmationRefus,
    DetailVoyage,
    PrintAttestation,
    PrintFacture,
  },
  computed: {
    ...mapGetters("saisons", ["selectedSaison"]),
  },
  data: () => ({
    openDeclineDialog: false,
    openDetailDialog: false,
    details: {},
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
  }),
  methods: {
    visualizeVoyage(item) {
      console.log(`item===>>`, item);
      this.openDetailDialog = true;
      // item.UTI_AssuranceVoyage.ADR_Pay.NomPays = item.ADR_Pay.NomPays;
      // console.log("item", item);
      this.details = item;
    },
    declineVoyage(item) {
      this.openDeclineDialog = true;
    },
    async declineVoyageUser() {
      console.log(`declined===>>`);
    },
    downloadFacture(item, name, utilisateur) {
      this.$refs.facture.printFacture(item, name, utilisateur);
    },
    downloadAttestation(item, name, utilisateur) {
      this.$refs.attestation.printAttestation(item, name, utilisateur);
    },
  },

  props: {
    items: {
      type: Array,
    },

    headers: {
      type: Array,
    },
    areFiltersOpened: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.list__table .v-data-table-header {
  background: rgba(230, 230, 230, 1);
}
th {
  background: lightgrey !important;
}

.table__structure_list {
  .list__table .v-data-table__wrapper {
    height: calc(100vh - 420px);
  }
  .list__table.filters-opened .v-data-table__wrapper {
    height: calc(100vh - 500px);
  }

  &.buttonLine {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 480px);
    }
  }

  &.membresFederaux {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 310px);
    }
  }
}

/* .listSearch {
    height: calc(100vh - 370px);
    overflow: hidden;
    overflow-y: scroll;
} */
.filters-opened {
  height: calc(100vh - 440px);
}
.periode__class {
  color: lightslategray;
}
</style>
