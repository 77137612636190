<template>
  <div>
    <subBar
      title="Gestion des voyages"
      route_name="gestion_de_la_structure__dashboard"
    ></subBar>
    <v-row no-gutters>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span v-if="panel === 0">Masquer les filtres</span>
              <span v-else>Afficher les filtres</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#f0f0f0">
              <ValidateForm @validated="submit" :showFieldObl="false">
                <v-row class="pt-5">
                  <v-col
                    cols="1"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.NumeroDossier"
                      rules="min:3"
                      placeholder="N° Dossier"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="1"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.NumeroLicence"
                      rules="min:3"
                      placeholder="N° Licence"
                    ></inputText>
                  </v-col>

                  <v-col
                    cols="2"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.Nom"
                      rules="min:2"
                      placeholder="Nom"
                    ></inputText>
                  </v-col>

                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.Prenom"
                      rules="min:2"
                      placeholder="Prénom"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 3px; padding-bottom: 0"
                  >
                    <countrySelectVoyage
                      v-model="search.Destination"
                      key="countrynationalite"
                      placeholder="Destination"
                      class="country__voyage"
                    />
                  </v-col>

                  <v-col
                    cols="1"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <selectInput
                      v-model="Saison"
                      :items="allFedeSaisons"
                      item_text="NomSaison"
                      item_value="id"
                      placeholder="Saison"
                    />
                  </v-col>

                  <v-col cols="4" lg="3">
                    <v-btn
                      @click.prevent="erraseFilter()"
                      outlined
                      color="indigo"
                      >Effacer tous les filtres</v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="pb-3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <v-btn
                      class="btn btn-primary mr-4"
                      type="submit"
                      :disabled="disabledSearch"
                      >Rechercher</v-btn
                    >
                    <!-- <v-btn
                      class="btn btn-primary"
                      :loading="loading"
                      @click="exportSearch"
                      :disabled="!!!structures.length"
                      >Exporter la recherche</v-btn
                    >
                    <JsonExcel
                      class="d-none"
                      ref="csvBtn"
                      :data="json_data"
                      :fields="json_fields"
                      name="filename.xls"
                      >Exporter</JsonExcel
                    > -->
                  </v-col>
                </v-row>
              </ValidateForm>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" lg="12">
        <VoyageListSearch
          :headers="tabHeaders"
          :items="voyages"
          :loading="loading"
          :panel="panel"
          :areFiltersOpened="panel === 0"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import subBar from "@/components/Common/Common__SubBar.vue";
import inputText from "@/components/utils/input";
import selectInput from "@/components/utils/select";
import ValidateForm from "@/components/utils/form";
// import JsonExcel from "vue-json-excel";
import VoyageListSearch from "@/components/Structure/Voyage/VoyageListSearch";
import countrySelectVoyage from "@/components/utils/select__countries__voyage";

export default {
  components: {
    subBar,
    inputText,
    ValidateForm,
    selectInput,
    // JsonExcel,
    VoyageListSearch,
    countrySelectVoyage,
  },

  data: () => ({
    listStructuresLigue: [],

    allVoyage: [],
    tabHeaders: [
      {
        text: "Saison",
        value: "ID_Saison",
      },
      {
        text: "N° Dossier",
        value: "Dossier",
      },

      // {
      //   text: "N° Licence",
      //   value: "UTI_Utilisateur.LicenceNumero",
      // },
      { text: "Licencié", value: "Licencié" },

      {
        text: "Date de la saisie",
        value: "dateSoumission",
      },

      { text: "Période", value: "periode" },
      { text: "Destination", value: "ADR_Pay.NomPays" },
      { text: "Facture", value: "factures" },
      { text: "Attestation", value: "attestations" },
      { text: "Etat", value: "LIST_EtatValidationVoyage.Description" },

      {
        text: "Action",
        value: "actions",
      },
    ],
    mounted: false,
    search: {},
    Saison: 2021,
    panel: 0,
    allFedeSaisons: [],
    voyages: [],
    loading: false,
  }),

  async created() {
    await this.setData();
    this.Saison = this.currentSaisonId;
    this.submit();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "hasCodeFederal"]),
    ...mapGetters("saisons", ["currentSaisonId"]),
    ...mapGetters("voyage", ["getVoyage"]),

    disabledSearch() {
      if (
        this.search.NumeroDossier ||
        this.search.Nom ||
        this.search.NumeroLicence ||
        this.search.Destination ||
        this.search.Prenom ||
        this.Saison
      ) {
        return false;
      } else return true;
    },

    whereClause() {
      //all fields search are completed
      if (
        this.search.Nom &&
        this.search.Prenom &&
        this.search.NumeroDossier &&
        this.search.Destination
        // &&
        // this.search.NumeroLicence
      ) {
        return {
          UTI_Utilisateur: {
            CT_Nom: { _eq: this.search.Nom },
            CT_Prenom: { _eq: this.search.Prenom },
          },
          NumeroDossier: { _eq: +this.search.NumeroDossier - 8500 },
          ID_Pays: { _eq: this.search.Destination },
          ID_Saison: { _eq: this.Saison },
        };
      }
      // Nom && Prenom && NumeroDossier
      if (this.search.Nom && this.search.Prenom && this.search.NumeroDossier) {
        return {
          UTI_Utilisateur: {
            CT_Nom: { _eq: this.search.Nom },
            CT_Prenom: { _eq: this.search.Prenom },
          },
          NumeroDossier: { _eq: +this.search.NumeroDossier - 8500 },
          ID_Saison: { _eq: this.Saison },
        };
      }
      // Nom && Prenom
      if (this.search.Nom && this.search.Prenom) {
        return {
          UTI_Utilisateur: {
            CT_Nom: { _eq: this.search.Nom },
            CT_Prenom: { _eq: this.search.Prenom },
          },
          ID_Saison: { _eq: this.Saison },
        };
      }
      // Nom
      if (this.search.Nom) {
        return {
          UTI_Utilisateur: { CT_Nom: { _ilike: this.search.Nom } },
          ID_Saison: { _eq: this.Saison },
        };
      }
      //Prenom
      if (this.search.Prenom) {
        return {
          UTI_Utilisateur: { CT_Prenom: { _ilike: this.search.Prenom } },
          ID_Saison: { _eq: this.Saison },
        };
      }
      //Destination
      if (this.search.Destination) {
        return {
          ID_Pays: { _eq: this.search.Destination },
          ID_Saison: { _eq: this.Saison },
        };
      }
      //NumeroDossier
      if (this.search.NumeroDossier) {
        return {
          NumeroDossier: { _eq: +this.search.NumeroDossier - 8500 },
          ID_Saison: { _eq: this.Saison },
        };
      }
      // Numero Licence
      if (this.search.NumeroLicence) {
        return {
          UTI_Utilisateur: {
            LicenceNumero: { _eq: +this.search.NumeroLicence },
          },
          ID_Saison: { _eq: this.Saison },
        };
      }
      // Saison
      if (this.Saison) {
        return {
          ID_Saison: { _eq: this.Saison },
        };
      }
      return "";
    },
  },

  methods: {
    ...mapActions("saisons", ["getSaisons"]),
    ...mapActions("voyage", ["getVoyagesFiltered"]),
    erraseFilter() {
      this.search = {};
      this.typeResultat = null;
      this.voyages = [];
      this.submit();
    },
    getVoyageData() {
      if (!this.getVoyage) return [];
      const allVoyage = this.getVoyage.map((voyage) => ({
        ...voyage,
        dateSoumission: this.$moment(voyage.Z_DateCreation).format(
          "DD/MM/YYYY"
        ),
        Dossier: voyage.NumeroDossier + 8500,
        depart: this.$moment(voyage.DateDepart).format("DD/MM/YYYY"),
        retour: this.$moment(voyage.DateRetour).format("DD/MM/YYYY"),
        Licencié: `${voyage.UTI_Utilisateur?.CT_Nom} ${voyage.UTI_Utilisateur?.CT_Prenom}`,
      }));
      this.voyages = [...allVoyage];
      this.loading = false;
    },
    async submit() {
      this.loading = true;
      await this.getVoyagesFiltered({ whereClause: this.whereClause });
      this.getVoyageData();
    },
    async setData() {
      this.allFedeSaisons = await this.getSaisons();
    },
  },
};
</script>

<style>
.v-text-field__details {
  display: none;
}

.v-text-field__slot input {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
