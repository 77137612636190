<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_de_la_structure__dashboard"
      />
    </v-row>

    <v-row v-if="mounted" class="px-6">
      <v-col cols="12" class="">
        <h4>Documents d'affiliation</h4>
      </v-col>

      <v-col cols="12" md="4">
        <documentAffiliation
          :documentType="StatusDocumentType"
          :document="DOC_Status"
          :ID_Structure="currentStructureId"
          @onDocumentSaved="documentSaved"
        >
          <template v-slot:sub_title>
            <h6>Status en vigueur au {{ $moment().format("DD/MM/YYYY") }}</h6>
          </template>
        </documentAffiliation>
      </v-col>

      <v-col cols="12" md="4">
        <documentAffiliation
          :documentType="CompteRenduAGDocumentType"
          :document="DOC_CompteRenduAg"
          :ID_Structure="currentStructureId"
          @onDocumentSaved="documentSaved"
        >
          <template v-slot:sub_title>
            <h6>Compte-rendu d'activités</h6>
          </template>
        </documentAffiliation>
      </v-col>

      <v-col cols="12" md="4">
        <documentAffiliation
          :documentType="TresorerieDocumentType"
          :document="DOC_RapportTresorerie"
          :ID_Structure="currentStructureId"
          @onDocumentSaved="documentSaved"
        >
          <template v-slot:sub_title>
            <h6>Rapport du trésorier</h6>
          </template>
        </documentAffiliation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import documentAffiliation from "@/components/Affiliations/Affiliation_StructureDocUpload.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: { SubBar, documentAffiliation },

  data: () => ({
    structureType: [],
    mounted: false,
    allDocumentsType: [],
    affiliation: null,
    DOC_Status: null,
    DOC_RapportTresorerie: null,
    DOC_CompteRenduAg: null,
  }),

  async created() {
    await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId", "currentStructureType"]),
    ...mapGetters("saisons", ["selectedSaison"]),

    subBarTitle() {
      return "Ressources documentaires";
    },

    clubsType() {
      return this.currentStructureType.EST_Club;
    },

    isClub() {
      return this.currentStructureType.EST_Club;
    },

    isCT() {
      return (
        this.currentStructureType.SlugTypeStructure == "comite_territorial"
      );
    },

    isLigue() {
      return this.currentStructureType.SlugTypeStructure == "ligue";
    },

    isFFME() {
      return (
        this.currentStructureType.SlugTypeStructure == "service_federal_ffme"
      );
    },

    isAssociation() {
      return this.currentStructureType.SlugTypeStructure == "club_association";
    },

    isSectionAssociation() {
      return (
        this.currentStructureType.SlugTypeStructure ==
        "club_section_association"
      );
    },

    isMembreAssocie() {
      return this.currentStructureType.SlugTypeStructure == "membre_associe";
    },

    isEtablissementAffilie() {
      return (
        this.currentStructureType.SlugTypeStructure == "etablissement_affilie"
      );
    },

    documentTypeStructure() {
      let docs = [];
      if (this.isClub) {
        docs = this.allDocumentsType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_club"
        );
      }
      if (this.isCT) {
        docs = this.allDocumentsType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_ct"
        );
      }
      if (this.isLigue) {
        docs = this.allDocumentsType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_ligue"
        );
      }
      return docs;
    },

    StatusDocumentType() {
      return this.documentTypeStructure.find(
        (doc) => doc.SlugTypeDocument == "status"
      );
    },

    CompteRenduAGDocumentType() {
      return this.documentTypeStructure.find(
        (doc) => doc.SlugTypeDocument == "compte_rendu_ag"
      );
    },

    TresorerieDocumentType() {
      return this.documentTypeStructure.find(
        (doc) => doc.SlugTypeDocument == "rapport_tresorerie"
      );
    },
  },

  methods: {
    ...mapActions("basics", ["getListStructureType", "getListTypesDocument"]),
    ...mapActions("structure", [
      "getStructureAffiliation",
      "updateAffiliationDocument",
    ]),

    async setData() {
      this.allDocumentsType = await this.getListTypesDocument();

      this.structureType = await this.getListStructureType();

      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSaison.id,
      });

      if (this.affiliation.DOC_Status) {
        this.DOC_Status = this.affiliation.DOC_Status;
      }

      if (this.affiliation.DOC_CompteRenduAg) {
        this.DOC_CompteRenduAg = this.affiliation.DOC_CompteRenduAg;
      }

      if (this.affiliation.DOC_RapportTresorerie) {
        this.DOC_RapportTresorerie = this.affiliation.DOC_RapportTresorerie;
      }

      return;
    },

    async documentSaved(document) {
      console.log(document);
      if (!document.documentType.id && !document.document.id)
        return error_notification(
          "Oups, une erreur st survenue lors de l'upload"
        );

      try {
        let payload = {
          ID_Structure: this.currentStructureId,
          ID_Saison: this.selectedSaison.id,
          ID_Doc: document.document.id,
          GQLType: document.documentType.SlugTypeDocument,
        };

        await this.updateAffiliationDocument(payload);
        this.setData();
        document.DocUploadComponent.modalIsOpen = false;
        document.DocUploadComponent.modalKey += 1;
        success_notification("Ficher uploadé");
      } catch (e) {
        console.log(e);
        error_notification("Oups, une erreur st survenue");
      }
    },
  },
};
</script>
