<template>
  <div>
    <v-dialog
      v-if="openModal"
      v-model="openModal"
      @click:outside="$emit('on-close')"
      @keydown.esc="$emit('on-close')"
      max-width="30vw"
    >
      <v-card class="modal__voyage__detail">
        <v-tabs background-color="blue-dark" dark grow>
          <v-tabs-slider></v-tabs-slider>
          <v-tab style="display: flex; flex-direction: column">
            Dossier N°
            {{ context.NumeroDossier + 8500 }}
            <v-icon
              class="close-icon"
              style="margin-top: -23px !important; margin-left: auto"
              @click="$emit('on-close')"
              >mdi-close</v-icon
            ></v-tab
          >
        </v-tabs>
        <v-card class="info__container" elevation="6">
          <div>
            <span class="font-weight-bold"> Départ :</span>
            {{ $moment(context.DateDepart).format("DD/MM/YYYY") }}
          </div>
          <div>
            <span class="font-weight-bold"> Retour :</span>
            {{ $moment(context.DateRetour).format("DD/MM/YYYY") }}
          </div>
          <div>
            <span class="font-weight-bold"> Soumission :</span>
            {{ $moment(context.Z_DateCreation).format("DD/MM/YYYY") }}
          </div>
          <div>
            <span class="font-weight-bold"> Licencié :</span>
            {{
              `${context.UTI_Utilisateur.CT_Nom} ${context.UTI_Utilisateur.CT_Prenom}`
            }}
          </div>
          <div>
            <span class="font-weight-bold"> N° Licence :</span>
            {{ `${context.UTI_Utilisateur.LicenceNumero}` }}
          </div>
          <div>
            <span class="font-weight-bold"> Destination :</span>
            {{ context.ADR_Pays.NomPays || "" }}  
          </div>

          <div>
            <span class="font-weight-bold"> Motif :</span>
            {{ context.Description }}
          </div>
          <div>
            <span class="font-weight-bold"> Activités :</span>
            <ol>
              <li
                v-for="(activite, index) in selectedActivites"
                :key="activite.id"
              >
                {{ `${index + 1}- ${activite.NomActivite}` }}
              </li>
            </ol>
          </div>
          <div>
            <span class="font-weight-bold"> Facture :</span>
            <v-icon
              size="1.7rem"
              color="light-blue"
              @click.prevent="
                printFacture(
                  context,
                  `facture-${context.UTI_Utilisateur.CT_Prenom}-${context.UTI_Utilisateur.CT_Nom}-saison${context.ID_Saison}`
                )
              "
              >mdi-file-pdf</v-icon
            >
          </div>
          <div>
            <span class="font-weight-bold"> Attestation :</span>
            <v-icon
              size="1.7rem"
              color="light-blue"
              @click.prevent="
                printAttestation(
                  context,
                  `attestation-${context.UTI_Utilisateur.CT_Prenom}-${context.UTI_Utilisateur.CT_Nom}-saison${context.ID_Saison}`
                )
              "
              >mdi-file-cloud</v-icon
            >
          </div>
          <div
            v-if="context.LIST_EtatValidationVoyage.Description !== 'Validé'"
          >
            <v-divider> </v-divider>
            <div>
              <span class="font-weight-bold"> Annulé le :</span>
              {{ $moment(context.DateAnnulation).format("DD/MM/YYYY") }}
            </div>
            <div>
              <span class="font-weight-bold"> Raison de l'annulation :</span>
              {{ context.MotifAnnulation }}
            </div>
          </div>
        </v-card>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- <v-btn
          color="blue-dark text-white font-weight-bold"
          rounded
          class="__full __icon-left"
          @click="$emit('answerYes')"
        >
          Fermer
        </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <PrintAttestation ref="attestation" :print="print"></PrintAttestation>
    <PrintFacture ref="facture" :print="print"></PrintFacture> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import PrintAttestation from "../../../../components/Assurances/PrintAttestation.vue";
// import PrintFacture from "../../../../components/Assurances/PrintFacture.vue";

export default {
  name: "DetailsVoyage",
  components: {
    // PrintAttestation,
    // PrintFacture,
  },
  data: () => ({
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
  }),
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("user", ["currentUserId"]),
    selectedActivites() {
      const allActivites = JSON.parse(this.context.Activites);
      if (!allActivites.length) return [];
      return allActivites;
    },
  },
  methods: {
    created() {
      console.log(`voyageDetail====>>`, this.context);
    },
    printAttestation(item, name) {
      this.$emit("onPrintAttestation", item, name, this.context)
      // this.$refs.attestation.printAttestation("details" ,name, item, this.context);
    },
    printFacture(item, name) {
      this.$emit("onPrintFacture", item, name, this.context)
      // this.$refs.facture.printFacture(name, item, this.context);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__voyage__detail header {
  display: flex;
  justify-content: center;
}
.info__container {
  margin: 20px 40px;
  padding: 40px;
}
</style>
