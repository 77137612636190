<template>
  <router-link
    v-if="!admin && auth && canAccess"
    :to="{ name: module_name }"
    :class="{
      link: true,
      'link--disabled': disabled,
    }"
    :disabled="disabled"
    :event="!disabled ? 'click' : ''"
  >
    <span class="nav-text" v-text="title"
  /></router-link>
  <router-link
    v-else-if="auth"
    :to="{ name: module_name }"
    :class="{
      link: true,
      'link--disabled': disabled,
    }"
    :disabled="disabled"
    :event="!disabled ? 'click' : ''"
  >
    <span class="nav-text" v-text="title" />
  </router-link>
  <router-link
    v-else-if="admin && canAccess"
    :to="{ name: module_name }"
    :class="{
      link: true,
      'link--disabled': disabled,
    }"
    :disabled="disabled"
    :event="!disabled ? 'click' : ''"
  >
    <span class="nav-text" v-text="title" />
  </router-link>
  <span v-else>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span class="nolink" v-on="on">{{ title }}</span>
      </template>
      <span
        >Vous n'avez pas les droits nécessaire pour accéder à ce module. Merci
        de contacter un administrateur.</span
      >
    </v-tooltip>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("user", ["can"]),
    ...mapGetters("user", ["currentUser", "getRole"]),

    canAccess() {
      return this.can(this.module_name);
    },

    isAdministrator() {
      let role = this.getRole.NomRole;
      if (
        role === "Administrateur" ||
        role === "Administrateur SI" ||
        role === "Administrateur AT"
      )
        return true;
      return false;
    },
  },

  props: {
    title: {
      type: String,
      default: "Module name",
    },
    directAccess: {
      type: Boolean,
      default: false,
    },
    module_name: {
      type: String,
    },

    auth: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.link,
.nolink {
  padding: 20px 10px;
  display: block;
  font-weight: 200 !important;
  font-size: 0.9rem !important;
  color: black;
  &.__exact-active {
    font-weight: 500 !important;
  }
}
.link {
  &--disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}
.nolink {
  color: gray;
  opacity: 0.8;
}
.nav-text {
  font-family: "Roboto";
  font-style: normal;
  // font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000 !important;
  text-transform: uppercase;
}
</style>
<style scoped>
.active {
  color: red !important;
}
</style>
