var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__structure_list"},[_c('v-data-table',{staticClass:"elevation-1 list__table",class:{ 'filters-opened': _vm.areFiltersOpened },attrs:{"headers":_vm.headers,"fixed-header":true,"loading":_vm.loading,"items":_vm.items,"item-key":"id"},scopedSlots:_vm._u([{key:"item.factures",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"light-blue","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadFacture(
                item,
                ("facture-" + (item.UTI_Utilisateur.CT_Prenom) + "-" + (item.UTI_Utilisateur.CT_Nom) + "-saison" + (item.ID_Saison)),
                item.UTI_Utilisateur
              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf")])],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger la facture de paiement")])])]}},{key:"item.attestations",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"light-blue","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadAttestation(
                item,
                ("attestation-" + (item.UTI_Utilisateur.CT_Prenom) + "-" + (item.UTI_Utilisateur.CT_Nom) + "-saison" + (item.ID_Saison)),
                item.UTI_Utilisateur
              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-cloud")])],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger l'attestation")])])]}},{key:"item.periode",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"periode__class"},[_vm._v(" "+_vm._s(item.depart)+" ")]),_c('br'),_c('span',{staticClass:"periode__class"},[_vm._v(" "+_vm._s(item.retour))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"green","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.visualizeVoyage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualiser la déclaration de voyage")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:"edit",staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"error","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.declineVoyage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Déclinez la demande de voyage à l'étranger")])])]}}])}),_c('ConfirmationRefus',{attrs:{"openDialog":_vm.openDeclineDialog},on:{"answerNo":function () { return (_vm.openDeclineDialog = false); },"answerYes":_vm.declineVoyageUser}}),_c('DetailVoyage',{key:_vm.details.id,attrs:{"openModal":_vm.openDetailDialog,"context":_vm.details},on:{"on-close":function () { return (_vm.openDetailDialog = false); },"onPrintAttestation":_vm.downloadAttestation,"onPrintFacture":_vm.downloadFacture}}),_c('PrintAttestation',{ref:"attestation",attrs:{"print":_vm.print}}),_c('PrintFacture',{ref:"facture",attrs:{"print":_vm.print}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }