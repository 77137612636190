<template>
  <ul class="d-flex flex-column justify-space-between text-center pl-0">
    <liNav
      v-if="isAdministrator"
      title="Administration"
      module_name="administration_information_structure"
      admin
    />
    <liNav
      title="Présentation"
      module_name="information_de_la_structure__presentation"
      auth
    />
    <liNav
      title="Organigramme"
      module_name="information_de_la_structure__organigramme"
      auth
    />
    <liNav
      :title="actualitesName"
      module_name="information_de_la_structure__news"
      auth
    />
    <liNav
      :title="domiciliationName"
      module_name="information_de_la_structure__domiciliation"
      auth
    />
    <liNav
      title="Accueil et contact"
      module_name="information_de_la_structure__accueil_et_contact"
      class="nav-text"
      auth
    />
    <liNav
      title="Documents officiels"
      module_name="information_de_la_structure__documents_officiels"
      auth
    />
    <liNav title="Finances" module_name="information_de_la_structure__finances" auth />
    <liNav
      title="Gestion des salariés"
      module_name="information_de_la_structure__salaries"
      auth
    />

    <!-- <liNav title="emailing" module_name="id_fuse_email" auth /> -->
    <liNav
      title="Réseaux sociaux"
      module_name="information_de_la_structure__reseaux_sociaux"
      auth
    />
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import liNav from '@/components/Common/Navigation__item';

export default {
  components: { liNav },
  computed: {
    ...mapGetters('structure', ['currentStructureType', 'currentStructure']),
    ...mapGetters('user', ['getRole']),

    actualitesName() {
      let type = this.currentStructureType.SlugTypeStructure;
      if (type == 'ligue') {
        return 'Actualités de la ligue';
      } else if (type == 'comite_territorial') {
        return 'Actualités du CT';
      } else {
        return 'Actualités du club';
      }
    },
    domiciliationName() {
      if (this.isClub) return 'Adresses';
      return 'Domiciliation';
    },

    isClub() {
      return this.currentStructure.STR_Structure.LIST_StructureType.EST_Club;
    },

    isAdministrator() {
      let role = this.getRole.SlugRole;
      if (
        role === 'super_administrateur' ||
        role === 'administrateur_vie_territoriale'
      )
        return true;
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-text {
  font-family: 'Roboto';
  font-style: normal;
  // font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000 !important;
  text-transform: uppercase;
}
</style>
